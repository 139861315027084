import React from 'react';
import logo from './cappello.png';
import './App.css';
import { Component } from "react";
import FormInput from './components/form-input.component';
import 'react-dropdown/style.css';
import { createData } from './firebase/firebase.utils'

class App extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      firstname: '',
      lastname: '',
      experienceValue: '',
      phone: '',
      email: '',
      covidDeclaration: '',
      subscribe: '',
      shop: ''

    };
    this.shop = {
      options: ['Ghandi Square', 'Newtown', 'Cape Town']
    }

  }

  handleSubmit = event => {
    event.preventDefault();
    createData( this.state )



    this.setState( {
      email: '',
      firstname: '',
      lastname: '',
      phone: '',
      experienceValue: '',
      subscribe: false
    } )
    alert( "Thank You!" )
  }


  handleChange = event => {
    const { value, name } = event.target;
    this.setState( { [name]: value } )
  }
  handleChangeCheckbox = event => {
    const { checked, name } = event.target;

    this.setState( { [name]: checked } )
  }



  render () {
    return (
      <div className="App">
        <div className="logo-style"> <img className="img-styles" src={logo} alt="cappello logo" /></div>

        <div className="test">
          <span></span>
          <div className="container">
            <form className="form-style" onSubmit={this.handleSubmit}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <label className="label-styles">Store:</label>
                    </td>
                    <td >
                      <select onChange={this.handleChange} value={this.state.shop} className="dropdown-style" id="myList" name="shop">
                        <option onChange={this.handleChange} name="shop" value={this.shop.options[0]}>{this.shop.options[0]}</option>
                        <option onChange={this.handleChange} name="shop" value={this.shop.options[1]}>{this.shop.options[1]}</option>
                        <option onChange={this.handleChange} name="shop" value={this.shop.options[2]}>{this.shop.options[2]}</option>

                      </select>
                    </td>

                  </tr>

                </tbody>
              </table>


              <FormInput type="text" id="firstname" name="firstname" placeholder="First Name" required value={this.state.firstname} handleChange={this.handleChange}></FormInput>
              <FormInput type="text" id="lastname" name="lastname" placeholder="Last Name" required value={this.state.lastname} handleChange={this.handleChange}></FormInput>
              <FormInput type="number" id="experienceValue" name="experienceValue" placeholder="What’s the value of the Cappello experience you could win?" required value={this.state.experienceValue} handleChange={this.handleChange}></FormInput>
              <FormInput type="tel" id="phone" name="phone" placeholder="Phone Number" required value={this.state.phone} handleChange={this.handleChange}></FormInput>
              <FormInput className="email-style" type="email" id="email" name="email" placeholder="Email" required value={this.state.email} onChange={this.handleChange}></FormInput>
              <br></br>
              <div className="input-styles">
                <table>
                  <tbody>
                    <tr></tr>
                    <tr>
                      <td>
                        <input type="checkbox" id="subscribe" name="subscribe" checked={this.state.subscribe} onChange={this.handleChangeCheckbox}></input>
                      </td>
                      <td>
                        <label className="label-styles">I would like to receive emails and offers from Cappello</label>
                      </td>

                    </tr>
                  </tbody>
                </table>


              </div>
              <div >
                <input className="submit-button" type='submit' value='Submit'></input>
              </div>
            </form>
          </div>
        </div>

      </div >
    );
  }
}

export default App;
