import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/auth';
var uuid = require( "uuid" );

const config = {
    apiKey: "AIzaSyBPpZp3xfZPKQkoDUMO2HtL2SB1tW3GDHY",
    authDomain: "cappello-db.firebaseapp.com",
    databaseURL: "https://cappello-db.firebaseio.com",
    projectId: "cappello-db",
    storageBucket: "cappello-db.appspot.com",
    messagingSenderId: "355282648519",
    appId: "1:355282648519:web:d77d8d35a4f099ab18ede2"
}
export const createData = async ( data ) => {
    var id = uuid.v4();
    const userRef = firestore.doc( `users/${id}` );
    const snapShot = await userRef.get();
    if ( !snapShot.exists ) {
        const createAt = new Date();
        try {
            await userRef.set( {
                createAt,
                data
            } )
        } catch ( error ) {
            console.log( 'error creating data', error.message )

        }
    }

}

firebase.initializeApp( config );
export const firestore = firebase.firestore();